import "./src/assets/styles/global.css"
import React from "react"
// import { TimeProvider } from './src/context/TimeContext';

export const wrapRootElement = ({ element }) => {
  console.log("Site developed by NeverNull GmbH (www.nevernull.io)")

  return element
  //
  // return <TimeProvider>
  //   {element}
  // </TimeProvider>;
}
